import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSelectedEvent,
  fetchCategoryPricingStats,
  fetchEventFiscalSerials,
  fetchEventHeavy,
} from '../redux/slices/eventsSlice';
import { fetchNotifications } from '../redux/slices/notificationsSlice';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { downloadFile, openFileInNewTab } from '../utils/fileUtils';
import { seatIdToSectionParentOwn } from '../utils/formatters';
import { eventUrl } from '../utils/urlUtils';
import dayjs from 'dayjs';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import LocationIcon from '@mui/icons-material/LocationOn';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Download from '@mui/icons-material/Download';
import TabPanel from '../components/TabPanel';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import GroupsIcon from '@mui/icons-material/Groups';
import CategoryConfigDialog from '../components/CategoryConfigDialog';
import ForSaleConfigDialog from '../components/ForSaleConfigDialog';
import EventsDrawer, { EventsDrawerType } from '../components/EventsDrawer';
import SalesChart from '../components/SalesChart';
import NoData from '../components/NoData';
import CategoryPricingStats from '../components/CategoryPricingStats';
import NotificationsDialog from '../components/NotificationsDialog';
import EventHistoryDialog from '../components/EventHistoryDialog';
import TicketPdfSelectorDialog from '../components/TicketPDFSelectorDialog';
import api from '../api';

function EventDetails() {
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCatConfig, setOpenCatConfig] = useState(false);
  const [openForSaleConfig, setOpenForSaleConfig] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [selectedOrdId, setSelectedOrdId] = useState(null);
  const [editEvent, setEditEvent] = useState(null);

  const event = useSelector(({ events: { selectedEvent } }) => selectedEvent);
  const isLoading = useSelector(({ events: { isLoading } }) => isLoading);
  const error = useSelector(({ events: { error } }) => error);

  const fiscalSerials = useSelector(({ events: { fiscalSerials } }) => fiscalSerials);
  const notifications = useSelector((state) => state.notifications.data);
  const categoryPricingStats = useSelector((state) => state.events.categoryPricingStats);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (error) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [error]);

  const copyScanUrl = useCallback(() => {
    const code = event.scan_code;
    navigator.clipboard
      .writeText(`${process.env.REACT_APP_PUBLIC_URL}/scan/${code}`)
      .then(() =>
        toast.success('Copied to clipboard', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      )
      .catch(() =>
        toast.error('An error occured, try again!', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      );
    handleClose();
  }, [event]);

  const openDocument = (documentType) => {
    return () => {
      const tabWindow = window.open('', '_blank');
      handleClose();
      api
        .get(`/events/${eventId}/fiscalDocuments?type=${documentType}`, { responseType: 'blob' })
        .then((res) => {
          const data = res.data;
          openFileInNewTab(tabWindow, data, 'application/pdf');
        })
        .catch((err) => {
          toast.error('An error occured please try again!', { position: toast.POSITION.BOTTOM_RIGHT });
        });
    };
  };

  const downloadOrdersCsv = (eventId) => {
    api
      .get(`/events/${eventId}/ordersCsv`, { responseType: 'blob' })
      .then((res) => {
        const data = res.data;
        downloadFile(data, 'text/csv', `orders_event_${eventId}.csv`);
      })
      .catch((err) => {
        toast.error('An error occured please try again!', { position: toast.POSITION.BOTTOM_RIGHT });
      });
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleOpenEdit = useCallback(() => {
    setEditEvent(event);
    handleClose();
  }, [event]);

  const toggleOpenNotifications = useCallback(() => {
    setOpenNotifications(!openNotifications);
    handleClose();
  }, [openNotifications]);

  const toggleOpenCatConfig = () => {
    setOpenCatConfig(!openCatConfig);
    handleClose();
  };

  const toggleOpenForSaleConfig = () => {
    setOpenForSaleConfig(!openForSaleConfig);
    handleClose();
  };

  const toggleHistory = () => {
    setOpenHistory(!openHistory);
    handleClose();
  };

  const toClient = () => {
    window.open(eventUrl(event));
    handleClose();
  };

  const onDrawerClose = () => {
    setEditEvent(null);
  };

  const statusChip = useMemo(() => {
    const { status } = event;

    if (!user || !status) return null;

    const chipLabel = status.replace('_', ' ');
    const chipColor =
      status === 'NOT_SELLING' || status === 'DELETED' ? 'error' : status === 'SELLING' ? 'success' : 'warning';
    const clickAction = user.role === 'organizer' ? { onClick: toggleOpenEdit } : {};

    return (
      <Chip
        {...clickAction}
        sx={{ mt: 1, borderRadius: 1 }}
        size="small"
        label={`${chipLabel}`}
        variant="outlined"
        color={chipColor}
      />
    );
  }, [event, user, toggleOpenEdit]);

  useEffect(() => {
    if (eventId) {
      dispatch(fetchEventHeavy({ eventId }));
      dispatch(fetchCategoryPricingStats({ eventId }));
      if (user.role === 'organizer') {
        dispatch(fetchEventFiscalSerials({ eventId }));
        dispatch(fetchNotifications({ eventId }));
      }
    }

    return () => {
      dispatch(clearSelectedEvent());
    };
  }, [dispatch, eventId, user.role]);

  const cashierComission = useMemo(
    () =>
      event.comission_cash_per_seat
        ? event.seat_price * categoryPricingStats.booked_cashier
        : categoryPricingStats.revenue_cashier * event.platform_comission,
    [
      categoryPricingStats.booked_cashier,
      categoryPricingStats.revenue_cashier,
      event.platform_comission,
      event.seat_price,
      event.comission_cash_per_seat,
    ]
  );

  const cashierComissionLabel = useMemo(
    () => (event.comission_cash_per_seat ? `${event.seat_price} Lei/seat` : `${event.platform_comission * 100}%`),
    [event.platform_comission, event.comission_cash_per_seat, event.seat_price]
  );

  const totalComission = useMemo(
    () => categoryPricingStats.revenue * event.platform_comission,
    [categoryPricingStats.revenue, event.platform_comission]
  );

  const subtotalOnline = useMemo(
    () => categoryPricingStats.revenue - totalComission,
    [categoryPricingStats, totalComission]
  );

  const subtotalCashier = useMemo(
    () => categoryPricingStats.revenue_cashier - cashierComission,
    [categoryPricingStats.revenue_cashier, cashierComission]
  );

  const total = useMemo(() => subtotalOnline + subtotalCashier, [subtotalOnline, subtotalCashier]);

  const totalBooked = useMemo(
    () => categoryPricingStats.booked + categoryPricingStats.booked_cashier + categoryPricingStats.booked_organizer,
    [categoryPricingStats]
  );

  return (
    <div className="event-details">
      <Box sx={{ position: 'relative' }}>
        <IconButton
          aria-label="more options"
          onClick={handleMenu}
          sx={{
            position: 'absolute',
            right: 8,
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!anchorEl}
          onClose={handleClose}
        >
          <Box>
            {user.role === 'cashier' && (
              <Box>
                <MenuItem onClick={toClient}>To client</MenuItem>
                <MenuItem onClick={toggleOpenForSaleConfig}>For Sale Config</MenuItem>
              </Box>
            )}
            {user.role === 'organizer' && (
              <Box>
                <MenuItem onClick={toClient}>To client</MenuItem>
                <MenuItem onClick={toggleOpenEdit}>Edit</MenuItem>
                <MenuItem onClick={toggleHistory}>History</MenuItem>
                <MenuItem onClick={copyScanUrl}>Copy scan link</MenuItem>
                <Divider />
                <MenuItem onClick={toggleOpenCatConfig}>Category Config</MenuItem>
                <MenuItem onClick={toggleOpenForSaleConfig}>For Sale Config</MenuItem>
                <Divider />
                <MenuItem onClick={openDocument('fiscalRegistration')}>Registration</MenuItem>
                <MenuItem onClick={openDocument('destroyedTickets')}>Destroyed Tickets</MenuItem>
                <MenuItem onClick={openDocument('soldTickets')}>Sold Tickets</MenuItem>
              </Box>
            )}
          </Box>
        </Menu>
        <Grid wrap="nowrap" container>
          <Grid item>
            {!event.portrait_image_url ? (
              <Skeleton variant="rectangular" width={200} height={280} />
            ) : (
              <img alt="" src={event.portrait_image_url} width="200px" height="100%" />
            )}
          </Grid>
          <Grid item>
            <Box sx={{ p: 2 }}>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  window.open(`${window.location.origin}/seats/${eventId}`);
                }}
                sx={{ color: 'white', cursor: 'pointer', mb: 2 }}
                underline="hover"
                variant="h4"
              >
                {event.title}
              </Link>
              <Box mb={2} />
              {user.role !== 'organizer' && (
                <Box display="flex" alignItems="center">
                  <GroupsIcon sx={{ mr: 1 }} fontSize="small" />
                  <Typography variant="subtitle1">{event.organizer}</Typography>
                </Box>
              )}
              <Box display="flex" alignItems="center">
                <CalendarIcon sx={{ mr: 1 }} fontSize="small" />
                <Typography variant="subtitle1">
                  {dayjs(event.date).format('DD/MM/YYYY')} {dayjs(event.date).format('HH:mm')}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <LocationIcon sx={{ mr: 1 }} fontSize="small" />
                <Typography variant="subtitle1">{`${event.location}`}</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <LocationCityIcon sx={{ mr: 1 }} fontSize="small" />
                <Typography variant="subtitle1">{`${event.city}`}</Typography>
              </Box>
              {statusChip}
              {!!event.is_one_plus_one_free && (
                <Chip
                  sx={{ ml: 1, mt: 1, borderRadius: 1 }}
                  size="small"
                  color="success"
                  variant="outlined"
                  label="1+1 Free"
                />
              )}
              {!!event.is_three_plus_one_free && (
                <Chip
                  sx={{ ml: 1, mt: 1, borderRadius: 1 }}
                  size="small"
                  color="success"
                  variant="outlined"
                  label="3+1 Free"
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)}>
          <Tab label="Details" />
          <Tab label="Orders" />
          {user.role === 'organizer' && <Tab label="Notifications" />}
          {user.role === 'organizer' && <Tab label="DGITL" />}
        </Tabs>
      </Box>
      <TabPanel disabled={isLoading} value={tabIndex} index={0}>
        <SalesChart event={event} />
        <CategoryPricingStats eventId={eventId} role={user.role} stats={categoryPricingStats} />
        {user.role === 'organizer' && (
          <TableContainer sx={{ marginTop: 2, width: '35%', marginLeft: 'auto' }} component={Box}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Summary</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    textAlign: 'right',
                    '&:last-child td, &:last-child th': { borderBottom: 0 },
                  }}
                >
                  <TableCell>Seats booked</TableCell>
                  <TableCell align="right">{totalBooked}</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    textAlign: 'right',
                    '&:last-child td, &:last-child th': { borderBottom: 0 },
                  }}
                >
                  <TableCell>Online revenue</TableCell>
                  <TableCell align="right">{categoryPricingStats.revenue.toFixed(2)} Lei</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    textAlign: 'right',
                    '&:last-child td, &:last-child th': { borderBottom: 0 },
                  }}
                >
                  <TableCell>{`Online comission (${event.platform_comission * 100}%)`}</TableCell>
                  <TableCell align="right">{totalComission.toFixed(2)} Lei</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    textAlign: 'right',
                    '&:last-child td, &:last-child th': { borderBottom: 0 },
                  }}
                >
                  <TableCell sx={{ fontWeight: 'bold' }}>Online subtotal</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="right">
                    {subtotalOnline.toFixed(2)} Lei
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    textAlign: 'right',
                    '&:last-child td, &:last-child th': { borderBottom: 0 },
                  }}
                >
                  <TableCell>Cashier revenue</TableCell>
                  <TableCell align="right">{categoryPricingStats.revenue_cashier.toFixed(2)} Lei</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    textAlign: 'right',
                    '&:last-child td, &:last-child th': { borderBottom: 0 },
                  }}
                >
                  <TableCell>{`Cashier comission (${cashierComissionLabel})`}</TableCell>
                  <TableCell align="right">{cashierComission.toFixed(2)} Lei</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    textAlign: 'right',
                    '&:last-child td, &:last-child th': { borderBottom: 0 },
                  }}
                >
                  <TableCell sx={{ fontWeight: 'bold' }}>Cashier subtotal</TableCell>
                  <TableCell align="right">{subtotalCashier.toFixed(2)} Lei</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    textAlign: 'right',
                    '&:last-child td, &:last-child th': { borderBottom: 0 },
                  }}
                >
                  <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="right">
                    {total.toFixed(2)} Lei
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </TabPanel>
      <TabPanel disabled={isLoading} value={tabIndex} index={1}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography gutterBottom variant="h5">
            Orders
          </Typography>
          <IconButton onClick={() => downloadOrdersCsv(eventId)} sx={{ mb: '0.35em' }} color="primary" size="medium">
            <Download />
          </IconButton>
        </Grid>
        {event.orders.length ? (
          <TableContainer sx={{ mt: 2 }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ '& > th': { whiteSpace: 'nowrap' } }}>
                  <TableCell align="left">Nr.</TableCell>
                  <TableCell align="left">Date/Time</TableCell>
                  <TableCell align="left">Category</TableCell>
                  <TableCell align="left">Price</TableCell>
                  <TableCell align="left">Order ID</TableCell>
                  <TableCell align="left">Seat ID</TableCell>
                  <TableCell align="left">Serial</TableCell>
                  <TableCell align="left">Fiscal Serial</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Phone</TableCell>
                  <TableCell align="left">Scanned</TableCell>
                  {user.role !== 'cashier' && <TableCell align="left">Originator</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {event.orders.map(
                  (
                    {
                      id,
                      type_label,
                      is_default_type,
                      created_at,
                      scanned,
                      serial,
                      fiscal_serial,
                      seatsio_object_id,
                      section,
                      parent,
                      own,
                      email,
                      first_name,
                      last_name,
                      phone,
                      price,
                      category_name,
                      is_organizer,
                      is_cashier,
                    },
                    index
                  ) => (
                    <TableRow
                      key={index}
                      sx={{
                        textAlign: 'right',
                        '& > td': { whiteSpace: 'nowrap' },
                        '&:last-child td, &:last-child th': { borderBottom: 0 },
                        '&:nth-of-type(odd)': (theme) => ({
                          backgroundColor: theme.palette.action.hover,
                        }),
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{dayjs(created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
                      <TableCell>
                        {category_name}
                        {!is_default_type && <Chip sx={{ marginLeft: 1 }} size="small" label={type_label} />}
                      </TableCell>
                      <TableCell>{price + ' Lei'}</TableCell>
                      <TableCell>
                        <Link
                          onClick={() => setSelectedOrdId(id)}
                          sx={{ color: 'white', cursor: 'pointer' }}
                          underline="hover"
                        >
                          {id}
                        </Link>
                      </TableCell>
                      <TableCell>{seatIdToSectionParentOwn(seatsio_object_id, section, parent, own)}</TableCell>
                      <TableCell>{serial}</TableCell>
                      <TableCell>{fiscal_serial || '-'}</TableCell>
                      <TableCell>{email || '-'}</TableCell>
                      <TableCell>
                        {first_name || last_name ? `${first_name || ''} ${last_name || ''}`.trim() : '-'}
                      </TableCell>
                      <TableCell>{phone || '-'}</TableCell>
                      <TableCell>{scanned ? 'Yes' : 'No'}</TableCell>
                      {user.role !== 'cashier' && (
                        <TableCell>{is_cashier ? 'cashier' : is_organizer ? 'organizer' : 'client'}</TableCell>
                      )}
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoData />
        )}
      </TabPanel>
      {user.role === 'organizer' && (
        <TabPanel disabled={isLoading} value={tabIndex} index={2}>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5">Notifications</Typography>
            </Grid>
            <Grid item>
              <Fab size="small" color="primary" disabled={event.orders.length <= 0} onClick={toggleOpenNotifications}>
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
          {notifications.length ? (
            <TableContainer sx={{ mt: 2 }} component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Created</TableCell>
                    <TableCell align="left">Channel</TableCell>
                    <TableCell align="left">Subject</TableCell>
                    <TableCell align="left">Body</TableCell>
                    <TableCell align="left">Attachments</TableCell>
                    <TableCell align="left">Message Count</TableCell>
                    <TableCell align="left">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notifications.map(
                    ({
                      id,
                      created_at,
                      channel,
                      subject,
                      body,
                      include_tickets_attachment,
                      scheduled_mesages,
                      price,
                    }) => (
                      <TableRow
                        key={id}
                        sx={{
                          textAlign: 'right',
                          '&:last-child td, &:last-child th': { borderBottom: 0 },
                          '&:nth-of-type(odd)': (theme) => ({
                            backgroundColor: theme.palette.action.hover,
                          }),
                        }}
                      >
                        <TableCell>{dayjs(created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
                        <TableCell>{channel}</TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              maxWidth: 400,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {subject || '-'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              maxWidth: 400,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {body}
                          </Typography>
                        </TableCell>
                        <TableCell>{include_tickets_attachment ? 'Yes' : 'No'}</TableCell>
                        <TableCell>{scheduled_mesages}</TableCell>
                        <TableCell>{price + ' Lei'}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <NoData />
          )}
        </TabPanel>
      )}
      {user.role === 'organizer' && (
        <TabPanel disabled={isLoading} value={tabIndex} index={3}>
          <Typography gutterBottom variant="h5">
            Company
          </Typography>
          <TableContainer sx={{ marginTop: 2, marginBottom: 2 }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Tax Code</TableCell>
                  <TableCell align="left">Platform Comission</TableCell>
                  <TableCell align="left">Seat Price</TableCell>
                  <TableCell align="left">Sms Price</TableCell>
                  <TableCell align="left">Email Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    textAlign: 'right',
                    '&:last-child td, &:last-child th': { borderBottom: 0 },
                    '&:nth-of-type(odd)': (theme) => ({
                      backgroundColor: theme.palette.action.hover,
                    }),
                  }}
                >
                  <TableCell>{event.company_name}</TableCell>
                  <TableCell>{event.tax_code}</TableCell>
                  <TableCell>{event.platform_comission * 100 + ' %'}</TableCell>
                  <TableCell>{event.seat_price ? event.seat_price + ' Lei' : '-'}</TableCell>
                  <TableCell>{event.notification_sms_price + ' Lei'}</TableCell>
                  <TableCell>{event.notification_email_price + ' Lei'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography gutterBottom variant="h5">
            DGITL
          </Typography>
          {fiscalSerials.length ? (
            <TableContainer sx={{ mt: 2 }} component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Category</TableCell>
                    <TableCell align="left">Price</TableCell>
                    <TableCell align="left">Serials</TableCell>
                    <TableCell align="left">Current Serial</TableCell>
                    <TableCell align="left">Total count</TableCell>
                    <TableCell align="left">Emitted</TableCell>
                    <TableCell align="left">Canceled</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fiscalSerials.map(
                    ({
                      id,
                      type_label,
                      is_default_type,
                      name,
                      prefix,
                      price,
                      discount,
                      start,
                      end,
                      count,
                      sold,
                      canceled,
                    }) => (
                      <TableRow
                        key={id}
                        sx={{
                          textAlign: 'right',
                          '&:last-child td, &:last-child th': { borderBottom: 0 },
                          '&:nth-of-type(odd)': (theme) => ({
                            backgroundColor: theme.palette.action.hover,
                          }),
                        }}
                      >
                        <TableCell>
                          {name}
                          {!is_default_type && <Chip sx={{ marginLeft: 1 }} size="small" label={type_label} />}
                        </TableCell>
                        <TableCell>{(price - price * discount).toFixed(2) + ' Lei'}</TableCell>
                        <TableCell>{`${prefix} ${start}-${end}`}</TableCell>
                        <TableCell>{sold ? `${prefix}-${start + sold - 1}` : '-'}</TableCell>
                        <TableCell>{count}</TableCell>
                        <TableCell>{sold}</TableCell>
                        <TableCell>{canceled}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <NoData />
          )}
        </TabPanel>
      )}
      <CategoryConfigDialog open={openCatConfig} onClose={toggleOpenCatConfig} event={event} />
      <ForSaleConfigDialog open={openForSaleConfig} onClose={toggleOpenForSaleConfig} event={event} />
      <NotificationsDialog event={event} open={openNotifications} onClose={toggleOpenNotifications} />
      {user.role === 'organizer' && (
        <EventsDrawer type={EventsDrawerType.UPDATE} open={!!editEvent} event={editEvent} onClose={onDrawerClose} />
      )}
      {user.role === 'organizer' && <EventHistoryDialog eventId={eventId} open={openHistory} onClose={toggleHistory} />}
      <TicketPdfSelectorDialog orderId={selectedOrdId} onClose={() => setSelectedOrdId(null)} />
    </div>
  );
}

export default EventDetails;
