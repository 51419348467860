import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeToken } from './redux/slices/authSlice';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuIcon from '@mui/icons-material/Menu';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Avatar from '@mui/material/Avatar';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import PeopleIcon from '@mui/icons-material/People';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import OrganizationIcon from '@mui/icons-material/CorporateFare';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DiscountIcon from '@mui/icons-material/Discount';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from './components/Link';
import Chip from '@mui/material/Chip';

const drawerWidth = 240;

const getInitials = (first_name, last_name) => {
  const a = first_name && first_name[0];
  const b = last_name && last_name[0];
  return a + b;
};

function ListItemLink(props) {
  const { icon, primary, to, disabled, comingSoon } = props;

  return (
    <li>
      <ListItem disabled={disabled} button component={Link} to={to}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
        {comingSoon && (
          <Chip sx={{ mt: 1, borderRadius: 1 }} size="small" label={`soon`} variant="contained" color="primary" />
        )}
      </ListItem>
    </li>
  );
}

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((state) => state.auth.user);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(removeToken());
    navigate('/login');
  };

  const redirect = useMemo(
    () => (user.role === 'organizer' || user.role === 'cashier' ? '/events' : '/orders'),
    [user.role]
  );

  useEffect(() => {
    if (location.pathname === '/') navigate(redirect);
  }, [navigate, redirect, location.pathname]);

  const drawer = (
    <div>
      <Toolbar />
      <List>
        {(user.role === 'organizer' || user.role === 'admin') && (
          <ListItemLink to="/venues" primary="Venues" icon={<LocationOnIcon />} />
        )}
        {user.role === 'organizer' && <ListItemLink to="/plays" primary="Plays" icon={<PlayCircleIcon />} />}
        {(user.role === 'organizer' || user.role === 'cashier') && (
          <ListItemLink to="/events" primary="Events" icon={<EventSeatIcon />} />
        )}
        {user.role === 'admin' && <ListItemLink to="/users" primary="Users" icon={<PeopleIcon />} />}
        {user.role === 'admin' && <ListItemLink to="/paymentPoints" primary="Payment Points" icon={<StoreIcon />} />}
        {user.role === 'admin' && <ListItemLink to="/organizers" primary="Organizers" icon={<OrganizationIcon />} />}
        <ListItemLink to="/orders" primary="Orders" icon={<ShoppingCartIcon />} />
        {user.role === 'admin' /*|| user.role === 'organizer' || user.role === 'cashier'*/ && (
          <ListItemLink to="/payments" primary="Payments" icon={<MoneyIcon />} />
        )}
        {user.role === 'admin' && <ListItemLink to="/artists" primary="Artists" icon={<TheaterComedyIcon />} />}
        {user.role === 'organizer' && (
          <ListItemLink comingSoon disabled to="/vouchers" primary="Vouchers" icon={<DiscountIcon />} />
        )}
        {user.role === 'organizer' && (
          <ListItemLink comingSoon disabled to="/reports" primary="Reports" icon={<AssessmentIcon />} />
        )}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            sx={{ flexGrow: 1, color: 'white', textDecoration: 'none' }}
            variant="h6"
            noWrap
            component={Link}
            to={'/'}
          >
            POFTIM CULTURA
          </Typography>
          {user && (
            <div>
              <IconButton onClick={handleMenu} size="small" sx={{ ml: 2 }}>
                <Avatar sx={{ width: 35, height: 35 }}>{getInitials(user.first_name, user.last_name)}</Avatar>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    minWidth: 200,
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
              >
                <Box pl={2} pr={2}>
                  <Typography variant="h6" noWrap sx={{ textOverflow: 'ellipsis' }}>
                    {user.organization}
                  </Typography>
                  <Typography variant="subtitle2" noWrap sx={{ textOverflow: 'ellipsis' }}>
                    {user.email}
                  </Typography>
                  <Typography variant="subtitle2" noWrap sx={{ textOverflow: 'ellipsis' }}>
                    {`${user.first_name} ${user.last_name}`}
                  </Typography>
                </Box>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, pl: 4, pr: 4, pt: 12, pb: 4, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        {/*<Container maxWidth="xxl">*/}
        <Outlet />
        {/*</Container>*/}
      </Box>
    </Box>
  );
}

export default App;
